import React, { Component } from "react"
import FormMaker from "../../../components/formMaker"
import { connect } from "react-redux"
import { storeCurrentRequestId } from "../../../redux/actions/"
import { Steps } from "intro.js-react"
import IntroWrapper from '../../../components/client-only-wrapper';

export class NewDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        showProgress: true,
        dontShowAgain: true,
        showBullets: false,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          element: "#brief",
          title: "Describe",
          intro:
            "Each document request can be very unique, so please be as specific as possible.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#content",
          title: "Content",
          intro:
            "If this document already exists, please include all the existing content/information.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".fileupload",
          title: "Upload File",
          intro:
            "This allows you to add inspiration/examples or any other relevant files.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".submitLater",
          title: "NOTE:",
          intro:
            "You can save what you have done so far and come back to it later Just remember that for it to be sent to head office you have to select the 'submit form' button",
          position: "top",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }
  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    console.log(this.props)
    return (
      <div>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>
        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>
        <FormMaker
          formId="602db440ba5f15001bb53018"
          formType="documents"
          id={`${"brief"} ${"content"} ${"promotion_detail"}`}
          fileupload="fileupload"
          submitLater="submitLater"
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  current_request_id: state.current_request_id,
})

function mapDispatchToProps(dispatch) {
  return {
    storeCurrentRequestId: id => dispatch(storeCurrentRequestId(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDocument)
